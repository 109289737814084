<template>
  <div>
    <!-- <h2 class="text-center">Charts.js</h2>
    <p class="category text-center">
      Simple yet flexible JavaScript charting for designers &amp; developers.
      Made by our friends from
      <a target="_blank" rel="noopener" href="https://vue-chartjs.org/"
        >Vue Charts.js</a
      >. Please check
      <a
        target="_blank"
        rel="noopener"
        href="https://vue-chartjs.org/guide/#vue-single-file-components"
        >the full documentation</a
      >.
    </p> -->

    <h2 class="text-center">{{ meter.desc }}</h2>
    <p class="category text-center">
    </p>


    <div class="row">

      <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.icon">
        <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon">
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div>


      <div class="col-lg-3 col-md-6" v-for="meter in devices" :key="meter.num">
        <meter-card :title="meter.desc" 
          sub-title="Watts"
           :type="warning"
            icon="tim-icons icon-chart-bar-32" 
            :footer="meter.desc"
            :device_code="meter.device_code"
            :device="meter.device"
            :num="meter.num"
            :tipo="meter.type"
            :id="meter.id"
             :route="'/meters/'+meter.id"
              @cli="onClickChild"> </meter-card>
      </div>

      <!-- <div class="col-lg-3 col-md-6">
      <stats-card
        :title="phase1"
        sub-title="Watts"
        type="info"
        icon="tim-icons icon-chart-bar-32"
      >
        <div slot="footer">
          <i class="tim-icons icon-sound-wave"></i>Phase 1
        </div>
      </stats-card>
    </div>
     <div class="col-lg-3 col-md-6">
      <stats-card
        :title="phase2"
        sub-title="Watts"
        type="info"
        icon="tim-icons icon-chart-bar-32"
      >
        <div slot="footer">
          <i class="tim-icons icon-sound-wave"></i>Phase 1
        </div>
      </stats-card>
    </div> -->

      <!-- <div class="col-lg-3 col-md-6">
      <stats-card
        :title="phase2+phase1"
        sub-title="Watts"
        type="info"
        icon="tim-icons icon-chart-bar-32"
      >
        <div slot="footer">
          <i class="tim-icons icon-sound-wave"></i>Phase 1
        </div>
      </stats-card>
    </div> -->


      <div class="col-md-12 ml-auto">
        <card class="card-chart" no-footer-line>
          <template slot="header">

             <div class="row">

          <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
         
            <h5 class="card-category">Energy consumption yearly</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-chart-bar-32 text-primary "></i>Total {{ grandtotal }} KWh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-primary "></i>Total ${{(grandtotal *   meter.price).toFixed(2)}}
            </h3>
 </div>

  <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
                <label
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active:true }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="downloadCSVData(lineChart1.chartData.datasets,lineChart1.chartData.labels)"
                    autocomplete="off"
                    
                  />
                  <span class="d-none d-sm-block">Export CSV</span>
                  <span class="d-block d-sm-none">
                    <!-- <i :class="option.icon"></i> -->
                  </span>
                </label>
              </div>
            </div>

   </div>
          </template>
          <div class="chart-area">

            <v-row v-if="componentKey0 === 0" align="center" justify="center">
              <v-col>
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
              </v-col>
            </v-row>


            <line-chart :chart-data="lineChart1.chartData" :gradient-colors="lineChart1.gradientColors"
              :gradient-stops="lineChart1.gradientStops" :extra-options="lineChart1.extraOptions" :height="200"
              :key="componentKey0">


            </line-chart>
          </div>
        </card>
      </div>


    </div>

    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <!-- <card class="card-subcategories card-plain"> -->
        <tabs type="primary" square centered class="row">
          <tab-pane>
            <span slot="label" v-on:click="change(0)">
              <i class="tim-icons icon-watch-time"></i>24H
            </span>


          </tab-pane>

          <tab-pane>
            <span slot="label" v-on:click="change(1)">
              <i class="tim-icons icon-single-copy-04"></i>30D
            </span>

          </tab-pane>

          <tab-pane>
            <span slot="label" v-on:click="change(2)">
              <i class="tim-icons icon-zoom-split"></i>Custom
            </span>

            <div class="row">



              <div class="col-md-6 ml-auto mr-auto">
                <base-input>

                  <el-date-picker v-model="from" type="datetime" placeholder="Select date and time from">
                  </el-date-picker>
                </base-input>

              </div>
              <div class="col-md-6 ml-auto mr-auto">

                <base-input>
                  <el-date-picker v-model="to" 
                  type="datetime"
                   placeholder="Select date and time to"
                   :picker-options="{ disabledDate: (time) => disabledDate1(time)}"
                   >
                  </el-date-picker>
                </base-input>


              </div>
              <div class="col-6">
                <base-button class="animation-on-hover" type="success" @click.native="load()">Load</base-button>
                <!-- <base-button class="animation-on-hover" type="success" @click.native="downloadCSVData()">downloadCSVData</base-button> -->
              </div>
            </div>

          </tab-pane>
        </tabs>
        <!-- </card> -->
      </div>
    </div>
    <p></p>
    <br>

    <!-- <div class="row" v-if="selected ===2">
        <div class="col-md-8 ml-auto mr-auto">
  <base-input>
  
  <el-date-picker v-model="from"
                    type="datetime"
                    placeholder="Select date and time from">
    </el-date-picker>
</base-input> 
 <base-input>
  <el-date-picker v-model="to"
                    type="datetime"
                    placeholder="Select date and time to">
    </el-date-picker>
</base-input>
</div>
        </div> -->

    <div class="row">
      <div class="col-md-12 ml-auto" v-if="selected === 0">
        <card class="card-chart">
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Energy consupmtion in last 24 hours</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-watch-time text-danger"></i> {{ total24 }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info"></i>${{(total24 * .17 / 1000).toFixed(2)}}
                </h3>
              </div>
              <div class="col-6" v-if="user.solar > 0">
                <h5 class="card-category">Energy production in last 24 hours</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-watch-time text-danger"></i> {{ total24solar }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info"></i>${{(total24solar * .17 / 1000).toFixed(2)}}
                </h3>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <v-row v-if="componentKey < 5" align="center" justify="center">
              <v-col>
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
              </v-col>
            </v-row>
            <line-chart :labels="lineChart2.labels" :chart-data="lineChart2.chartData"
              :extra-options="lineChart2.extraOptions" :height="200" :key="componentKey">
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>
      <div class="col-md-12 ml-auto" v-if="selected === 1">
        <card class="card-chart">
          <template slot="header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Energy consupmtion in last 30 days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-single-copy-04 text-danger "></i> {{ total30.toFixed(2) }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info "></i>${{(total30 * .17 / 1000).toFixed(2)}}
                </h3>
              </div>
              <div class="col-6" v-if="user.solar > 0">
                <h5 class="card-category">Energy production in last 30 days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-watch-time text-danger"></i> {{ total30solar }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info"></i>${{(total30solar * .17 / 1000).toFixed(2)}}
                </h3>
              </div>

            </div>
          </template>
          <div class="chart-area">
            <v-row v-if="componentKey2 < 7" align="center" justify="center">
              <v-col>
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
              </v-col>
            </v-row>
            <line-chart :labels="lineChart3.labels" :chart-data="lineChart3.chartData"
              :extra-options="lineChart3.extraOptions" :height="200" :key="componentKey2">
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>

      <div class="col-md-12 ml-auto" v-if="selected === 2">
        <card class="card-chart">
          <template slot="header">



            <div class="row">
              <div class="col-6">
                <h5 class="card-category">Energy consupmtion custom</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-watch-time text-danger "></i> {{ totalcustom }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info "></i>${{(totalcustom * .17 / 1000).toFixed(2)}}
                </h3>
              </div>
              <div class="col-6" v-if="user.solar > 0">
                <h5 class="card-category">Energy production custom</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-watch-time text-danger"></i> {{ totalcustomsolar }} Wh
                </h3>
                <h3 class="card-title">
                  <i class="tim-icons icon-money-coins text-info"></i>${{(totalcustomsolar * .17 / 1000).toFixed(2)}}
                </h3>
              </div>

            </div>

          </template>
          <div class="chart-area">
            <v-row v-if="customload" align="center" justify="center">
              <v-col>
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
              </v-col>
            </v-row>
            <line-chart :labels="lineChart4.labels" :chart-data="lineChart4.chartData"
              :extra-options="lineChart4.extraOptions" :height="200" :key="componentKey3">
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>

      <!-- <div class="col-md-5 mr-auto">
        <card class="card-chart" no-footer-line>
          <template slot="header">
            <h5 class="card-category">Multiple Bars Chart With Grid</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-time-alarm text-warning "></i> 130,000$
            </h3>
          </template>
          <div class="chart-area">
            <bar-chart
              :chart-data="barChart2.chartData"
              :extra-options="barChart2.extraOptions"
              :height="200">
            </bar-chart>
          </div>
        </card>
      </div> -->
    </div>

    <!-- <div class="row">
      <div class="col-md-5 ml-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">Simple Pie Chart</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart1.chartData"
                  :extra-options="pieChart1.extraOptions"
                  :height="120">
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-trophy text-success "></i> 10.000$
              </h4>
              <p class="category">A total of $54000</p>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-5 mr-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">Multiple Pie Chart</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart2.chartData"
                  :extra-options="pieChart2.extraOptions"
                  :height="120"
                >
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-tag text-warning "></i> 130,000
              </h4>
              <p class="category">Feedback from 20.000 users</p>
            </div>
          </div>
        </card>
      </div>
    </div> -->
  </div>
</template>
<script>
import LineChart from 'src/components/Charts/LineChart';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import MeterCard from 'src/components/Cards/MeterCard';
// import BarChart from 'src/components/Charts/BarChart';
// import PieChart from 'src/components/Charts/PieChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import { TabPane, Tabs } from 'src/components';
import { DatePicker, TimeSelect } from 'element-ui'
import moment from 'moment';
import firebase from '../Firebase';
import StatsCard from 'src/components/Cards/StatsCard';
import axios from 'axios';
import supabase from '@/supabase.js'




export default {
  components: {
    MeterCard,
    LineChart,
    // BarChart,
    // PieChart,
    PulseLoader,
    TabPane,
    Tabs,
    StatsCard,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
      disabledDate:this.disabledDate1,
      user: {},
      devices: [],
      lasttime: 0,
      timestamp: 0,
      handle: {},
      phase1: 0,
      phase2: 0,
      phase3: 0,
      current1: 0,
      current2: 0,
      current3: 0,
      day: '',
      energyf: '',
      energy: '',
      from: '',
      to: '',
      days: [],
      days30: [],
      hours: [],
      hour24: [],
      total24: '',
      total24solar: '',
      total30: 0,
      total30solar: '',
      custom: [],
      custom8: [],
      totalcustom: '',
      totalcustomsolar: '',
      meter: "",
      grandtotal: 0,
      loaded: false,

      datatest: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      customhours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      selected: 0,
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      componentKey0: 0,
      customload: false,
      lineChart1: {
        chartData: {
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Phase A',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
            //  yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'Phase B',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
           //   yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'Total',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
           //   yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            // {
            //   label: '°F',
            //   fill: true,
            //   borderColor: config.colors.danger,
            //   borderWidth: 2,
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   pointBackgroundColor: config.colors.danger,
            //   pointBorderColor: 'rgba(255,255,255,0)',
            //   pointHoverBackgroundColor: '#2380f7',
            //   pointBorderWidth: 20,
            //   pointHoverRadius: 4,
            //   pointHoverBorderWidth: 15,
            //   pointRadius: 4,
            //   yAxisID: 'B',
            //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
            // }

          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart2: {
        chartData: {
          labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
          datasets: [
            {
              label: 'Phase A',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'Phase B',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'total',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
            // {
            //   label: '°F',
            //   fill: true,
            //   borderColor: config.colors.danger,
            //   borderWidth: 2,
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   pointBackgroundColor: config.colors.danger,
            //   pointBorderColor: 'rgba(255,255,255,0)',
            //   pointHoverBackgroundColor: '#2380f7',
            //   pointBorderWidth: 20,
            //   pointHoverRadius: 4,
            //   pointHoverBorderWidth: 15,
            //   pointRadius: 4,
            //   yAxisID: 'B',
            //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            // }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      lineChart3: {
        chartData: {
          labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
          datasets: [
          {
              label: 'Phase A',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'Phase B',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'total',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
            // {
            //   label: '°F',
            //   fill: true,
            //   borderColor: config.colors.danger,
            //   borderWidth: 2,
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   pointBackgroundColor: config.colors.danger,
            //   pointBorderColor: 'rgba(255,255,255,0)',
            //   pointHoverBackgroundColor: '#fff019',
            //   pointBorderWidth: 20,
            //   pointHoverRadius: 4,
            //   pointHoverBorderWidth: 15,
            //   pointRadius: 4,
            //   yAxisID: 'B',
            //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            // }

          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      lineChart4: {
        chartData: {
          labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
          datasets: [
          {
              label: 'Phase A',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'Phase B',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: 'total',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
            // {
            //   label: '°F',
            //   fill: true,
            //   borderColor: config.colors.danger,
            //   borderWidth: 2,
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   pointBackgroundColor: config.colors.danger,
            //   pointBorderColor: 'rgba(255,255,255,0)',
            //   pointHoverBackgroundColor: '#2380f7',
            //   pointBorderWidth: 20,
            //   pointHoverRadius: 4,
            //   pointHoverBorderWidth: 15,
            //   pointRadius: 4,
            //   yAxisID: 'B',
            //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            // }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      statsCards: [
        {
          title: '0.00',
          subTitle: 'Cost per hour $',
          type: 'warning',
          icon: 'tim-icons icon-bank',
          footer: '<i class="tim-icons icon-money-coins"></i> fixed rate',
          route: ''
        },
        {
          title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>total',
          route: ''
        },
        {
          title: '0.00',
          subTitle: 'Watts',
          type: 'info',
          icon: 'tim-icons icon-chart-bar-32',
          footer: '<i class="tim-icons icon-sound-wave"></i>Phase A',
          route: ''
        },
        {
          title: '0.00',
          subTitle: 'Watts',
          type: 'info',
          icon: 'tim-icons icon-chart-bar-32',
          footer: '<i class="tim-icons icon-sound-wave"></i>Phase B',
          route: ''
        }
      ]




    };
  },

  mounted: function () {

    //this.createPost()

    this.handle = setInterval(() => {



      if (this.timestamp < moment().subtract(2, 'minutes').unix() * 1000) {
        // console.log("FALSE ")
        // console.log(moment().subtract(2,'minutes').unix()*1000)

        this.statsCards[1].title = "0.00";
        this.statsCards[2].title = "0.00";
        this.statsCards[3].title = "0.00";

        this.phase1 = 0;
        this.phase2 = 0;
        this.phase3 = 0;

        this.statsCards[0].title = ((this.phase1 + this.phase2+this.phase3) *   this.meter.price / 1000).toFixed(2);
        this.statsCards[1].footer = '<i class="tim-icons icon-sound-wave"></i></i>total ' + ((this.phase1 + this.phase2)).toFixed(2) + " Watts";

      } else {


        switch (this.meter.tipo) {
        case 'meter3':
          
        this.statsCards[0].title = ((this.phase1 + this.phase2+this.phase3) *   this.meter.price / 1000).toFixed(2);
       
          break;

          case 'meter3-400':


           this.statsCards[0].title = ((this.phase1 + this.phase2+this.phase3) *   this.meter.price / 1000).toFixed(2);

          
          break;
       
        default:
        this.statsCards[0].title = ((this.phase1 + this.phase2+this.phase3) *   this.meter.price / 1000).toFixed(2);
        this.statsCards[1].footer = '<i class="tim-icons icon-sound-wave"></i></i>total ' + ((this.phase1 + this.phase2+this.phase3)).toFixed(2) + " Watts";


          break;
       }



       
      }
    }, 5000)
  },
  destroyed: function () {

    clearInterval(this.handle);
  },
  async created() {
 
    // this.teste()



    this.lasttime = moment().valueOf();

    //var user = {};
    this.user = this.$user;

    //console.log("-->",this.$route);


    if (this.$route.params.id) {
     // console.log("ID:" + this.$route.params.id)

       const { data, error } = await supabase
  .from('devices')
  .select('id,device_code,desc,type,price')
  .eq('id', this.$route.params.id).single()

  //console.log(data);

      this.meter = {id:this.$route.params.id,code:data.device_code,desc:data.desc,tipo:data.type,price:data.price};//
    } else {
 console.log("!!!!!-----1!!!!!");
      console.log(this.user)
      console.log("!!!!!-----1!!!!!");
      const { data, error } = await supabase
      .from('user_devices')
  .select(' devices(id,device_code,desc,num,device,type,price)')
  .eq('customer_id', this.user.id).single()

  console.log("MEtrs:",data);


      this.meter = {id:data.devices.id,code:data.devices.device_code,tipo:data.devices.type,desc:data.devices.desc,price:data.devices.price};//

      
    }
    console.log("meter :",this.meter)

  

    this.checkmeter(this.meter.tipo)

this.checkdevices(this.meter.code);

    // firebase.firestore().collection("devices").where('meterid', '==', this.meter).get().then((doc) => {


    //   doc.docs.forEach(snapshot => {

    //     const room = snapshot.data();
    //     //console.log(room);
    //     this.devices.push(room);

    //   });


    // });

   // console.log("SOLAR:" + this.user.solar)

    if (false) {
    //  if (this.user.solar > 0) {

      console.log("SOLAR: FOI")

      this.lineChart1.chartData.datasets.push(

        {
          label: 'Solar KWh',
          fill: true,
          borderColor: config.colors.solar,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.solar,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#fff019',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      )
      this.lineChart2.chartData.datasets.push(
        {
          label: 'Solar Wh',
          fill: true,
          borderColor: config.colors.solar,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.solar,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#fff019',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      )
      this.lineChart3.chartData.datasets.push(
        {
          label: 'Solar Wh',
          fill: true,
          borderColor: config.colors.solar,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.solar,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#fff019',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      )
      this.lineChart4.chartData.datasets.push(
        {
          label: 'Solar Wh',
          fill: true,
          borderColor: config.colors.solar,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.solar,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#fff019',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: []
        }
      )
    }








    firebase.database()
      .ref("smeter").child(this.meter.code).child("instant")
      //.orderByChild("timestamp").startAt(moment()
      //.subtract(2,'minutes').unix()*1000)
      //.ref('manual').child("test")
      .on("value", notes => {

        switch (this.meter.tipo) {
        case 'meter3':
          
 //
        this.phase1 = parseFloat(notes.child("0_power").val());
        this.phase2 = parseFloat(notes.child("1_power").val());
        this.phase3 = parseFloat(notes.child("2_power").val());

       // console.log("meter3: phase2", this.phase2);

        this.statsCards[1].title = notes.child("0_voltage").val();
        this.statsCards[2].title = notes.child("1_voltage").val();
        this.statsCards[3].title = notes.child("2_voltage").val();

        this.statsCards[5].title = Number(this.phase1)>0?this.phase1:"0.00"
         this.statsCards[6].title = Number(this.phase2)>0?this.phase2:"0.00"
        this.statsCards[7].title = Number(this.phase3)>0?this.phase3:"0.00";

        this.statsCards[4].title = (this.phase1 + this.phase2 + this.phase3).toFixed(2);

          break;

          case 'meter3-400':

         // console.log("meter3-400:", notes);
        this.phase1 = parseFloat(notes.child("a_act_power").val());
        this.phase2 = parseFloat(notes.child("b_act_power").val());
        this.phase3 = parseFloat(notes.child("c_act_power").val());

        this.statsCards[1].title = notes.child("a_voltage").val();
        this.statsCards[2].title = notes.child("b_voltage").val();
        this.statsCards[3].title = notes.child("c_voltage").val();

        this.statsCards[1].footer ='A <i class="tim-icons icon-sound-wave"></i></i>Current ' + notes.child("a_current").val()+' A';
        this.statsCards[2].footer ='B <i class="tim-icons icon-sound-wave"></i></i>Current ' + notes.child("b_current").val()+' A';
        this.statsCards[3].footer ='C <i class="tim-icons icon-sound-wave"></i></i>Current ' + notes.child("c_current").val()+' A';


        this.statsCards[5].title = Number(this.phase1)>0?this.phase1:"0.00"
         this.statsCards[6].title = Number(this.phase2)>0?this.phase2:"0.00"
        this.statsCards[7].title = Number(this.phase3)>0?this.phase3:"0.00";


        this.statsCards[5].footer ='A <i class="tim-icons icon-sound-wave"></i></i>App Power ' + notes.child("a_current").val()+' VA '+ '- PF: '+ notes.child("a_pf").val();
        this.statsCards[6].footer ='B <i class="tim-icons icon-sound-wave"></i></i>App Power ' + notes.child("b_current").val()+' VA'+ '- PF: '+ notes.child("b_pf").val();
        this.statsCards[7].footer ='C <i class="tim-icons icon-sound-wave"></i></i>App Power ' + notes.child("c_current").val()+' VA'+ '- PF: '+ notes.child("c_pf").val();


       this.statsCards[4].title = Number(notes.child("total_act_power").val())>0?notes.child("total_act_power").val():"0.00";
       this.statsCards[4].footer ='Curr. '+notes.child("total_current").val() +' A'+' <i class="tim-icons icon-sound-wave"></i></i>App Power ' + notes.child("total_aprt_power").val()+' VA';



      //   this.current1 = parseFloat(notes.child("a_current").val());
      //   this.current2 = parseFloat(notes.child("c_current").val());
      //   this.current3 = parseFloat(notes.child("c_current").val());
          
          break;
       
        default:
        this.statsCards[1].title = notes.child("0_voltage").val();
        this.statsCards[2].title = notes.child("0_power").val();
        this.statsCards[3].title = notes.child("1_power").val();
 
        this.phase1 = parseFloat(notes.child("0_power").val());
        this.phase2 = parseFloat(notes.child("1_power").val());


          break;
       }

       this.timestamp = notes.child("timestamp").val()
       
       

      });


    //console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
    this.lasttime = moment().valueOf();
    ////  console.log("init----");

    let currentTime = new Date();

    var { data, error } = await supabase.rpc('year_energy', { meter:this.meter.code,year:moment().local().format("YYYY")  })


    console.log("#####");
    if (error) console.error(error)
  //  else console.log(data);
  
var yeardataph1=[];
var yeardataph2=[];
var yeardataph3=[];

//this.lineChart1.chartData.datasets[2].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];





for (let index = 0; index < 12; index++) {

  const result = data.find(({ time }) => time === index+1);

  if(result){
     yeardataph1.push(result.one_consumption);
     yeardataph2.push(result.two_consumption);
     yeardataph3.push(result.three_consumption)
  }else{
    yeardataph1.push(0);
     yeardataph2.push(0);
     yeardataph3.push(0)

  }
  
}

    for (let index = 11; index > -1; index--) {

     // let currentmonth = new Date(moment().subtract(index, 'months').startOf('month').unix() * 1000);

     

      // if (this.user.solar > 0) {

      //   let datasolar = await this.getSolarMonth(index, this.meter);
      //   this.lineChart1.chartData.datasets[3].data[currentmonth.getMonth()] = datasolar[2];

      // }
     
      this.lasttime = moment().valueOf();

   
      if(this.meter.tipo==='meter'){

        let databig = [yeardataph1[index],yeardataph2[index],yeardataph1[index]+yeardataph2[index]];
      
        this.lineChart1.chartData.datasets[0].data[index] = (databig[0]/ 1000).toFixed(2);
      this.lineChart1.chartData.datasets[1].data[index] = (databig[1]/ 1000).toFixed(2);


        this.lineChart1.chartData.datasets[2].data[index] = (databig[2]/ 1000).toFixed(2);
      }else{

        let databig = [
        yeardataph1[index],
        yeardataph2[index],
        yeardataph3[index],
        yeardataph1[index]+yeardataph2[index]+yeardataph3[index]];
      
      //  console.log(index,databig)
      this.lineChart1.chartData.datasets[0].data[index] = (databig[0]/ 1000).toFixed(2);
    this.lineChart1.chartData.datasets[1].data[index] = (databig[1]/ 1000).toFixed(2);
   this.lineChart1.chartData.datasets[2].data[index] = (databig[2]/ 1000).toFixed(2);
    this.lineChart1.chartData.datasets[3].data[index] = (databig[3]/ 1000).toFixed(2);



      }

      //this.lineChart1.chartData.datasets[3].data = await this.Temperature_Yearly();


    }
    //console.log("month----");
   if(this.meter.tipo==='meter'){
      this.grandtotal = this.lineChart1.chartData.datasets[2].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2)
     }else{
      this.grandtotal = this.lineChart1.chartData.datasets[3].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2)
   }
   
    //this.loaded = true;
    
    this.forceRerender0();

   
    // moment().local().format("H")ƒ
    // moment().format("H")
    
    // moment().local().format("D")
    // moment().local().format("YYYY")

    
  
    // if (this.user.solar > 0) {
    //   this.total24solar = this.lineChart2.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
    // }
  
    this.chart24();

    // console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
    this.lasttime = moment().valueOf();
    //console.log("final hour----");
    this.forceRerender();
   // this.Temp();


  },

  methods: {

  downloadCSVData(data,labels) {

    let csv = '';
    labels.splice(0, 0, "phase");
    console.log("labels:",labels)
    csv += labels.join(',');
            csv += "\n";
    // labels.forEach((row) => {
    //         csv += row.join(',');
    //         csv += "\n";
    // });

    var array = [];
    var numph=0
    var ph=['A','B','C','Total'];

    if(this.meter.tipo==='meter'){

      ph.splice(2, 1);

    }

    data.forEach((info) => {

      info.data.splice(0, 0, ph[numph])

      array.push( info.data);
      numph++;
      
    });

    console.log("rara:",array)

    array.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
    });
 
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    anchor.download = 'yearly_energy_'+this.meter.desc+'_'+moment().format()+'.csv';
    anchor.click();
},
async chart24(){

  var { data, error } = await supabase.rpc('twofour_energy', { meter:this.meter.code,year:moment().local().format("YYYY"),day:moment().local().format("D"), zone:""  })


console.log("#####");
if (error) console.error(error)
//else console.log(data);

this.hours = []


    for (let hour = 23; hour > -1; hour--) {

     // console.log("hour",moment().subtract(hour, 'hours').format("H")); 

      var timeloop =  Number(moment().subtract(hour, 'hours').format("H"));

      const result24 = data.find(({ time }) => time === timeloop);

      // console.log("hour",hour);
     //  console.log("resut",result);

  if(result24){

    
   // if(this.meter.tipo==='meter'){
    this.lineChart2.chartData.datasets[0].data[23-hour] = parseFloat(result24.one_consumption).toFixed(2);
      this.lineChart2.chartData.datasets[1].data[23-hour] = parseFloat(result24.two_consumption).toFixed(2);

      if(this.meter.tipo==='meter'){
      this.lineChart2.chartData.datasets[2].data[23-hour] = parseFloat(result24.total_consumption).toFixed(2);
      }else{
        this.lineChart2.chartData.datasets[2].data[23-hour] = parseFloat(result24.three_consumption).toFixed(2);
        this.lineChart2.chartData.datasets[3].data[23-hour] = parseFloat(result24.total_consumption).toFixed(2);


      }
    // }else{

    //   this.lineChart2.chartData.datasets[0].data[23-hour] = parseFloat(result.one_consumption).toFixed(2);
    //   this.lineChart2.chartData.datasets[1].data[23-hour] = parseFloat(result.two_consumption).toFixed(2);
    //   this.lineChart2.chartData.datasets[2].data[23-hour] = parseFloat(result.three_consumption).toFixed(2);
    //   this.lineChart2.chartData.datasets[3].data[23-hour] = parseFloat(result.total_consumption).toFixed(2);


    // }
    
  }
  
  else{
if(this.meter.tipo==='meter'){
    this.lineChart2.chartData.datasets[0].data[23-hour] = parseFloat(0).toFixed(2);
      this.lineChart2.chartData.datasets[1].data[23-hour] = parseFloat(0).toFixed(2);
      this.lineChart2.chartData.datasets[2].data[23-hour] = parseFloat(0).toFixed(2);
}else{
  this.lineChart2.chartData.datasets[0].data[23-hour] = parseFloat(0).toFixed(2);
      this.lineChart2.chartData.datasets[1].data[23-hour] = parseFloat(0).toFixed(2);
      this.lineChart2.chartData.datasets[2].data[23-hour] = parseFloat(0).toFixed(2);
      this.lineChart2.chartData.datasets[3].data[23-hour] = parseFloat(0).toFixed(2);
    //  this.lineChart2.chartData.datasets[3].data[23-hour] = parseFloat(0).toFixed(2);

}

 }

      

      // if (this.user.solar > 0) {

      //   let hoursolar = await this.hourSolar(hour, this.meter);

      //   this.lineChart2.chartData.datasets[1].data[23 - hour] = parseFloat(hoursolar).toFixed(2);

      // }

      this.hours.push(moment().utcOffset(-240).subtract(hour, 'hours').format("hA"))
      this.hour24.push(moment().utcOffset(-240).subtract(hour, 'hours').hours())

      if (hour % 5 === 0) {
        this.forceRerender();

      }

    }
    this.lineChart2.chartData.labels = this.hours;



    //this.id=0;
    // console.log("source chats 24hr inside:",this.lineChart2.chartData.datasets)

    if(this.meter.tipo==='meter'){
    this.total24 = this.lineChart2.chartData.datasets[2].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
    }else{
      this.total24 = this.lineChart2.chartData.datasets[3].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);

    }



},

 checkmeter(tipo)
    {

      switch (tipo) {
        case 'meter3':
          
        var phasec24= {
              label: 'Phase C',
              fill: true,
              borderColor: config.colors.default,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.default,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
             // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }

            var phasec= {
              label: 'Phase C',
              fill: true,
              borderColor: config.colors.default,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.default,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
             // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }

             this.lineChart1.chartData.datasets.splice(2, 0, phasec);
            this.lineChart2.chartData.datasets.splice(2, 0, phasec24);
            this.lineChart3.chartData.datasets.splice(2, 0, phasec24);
            this.lineChart4.chartData.datasets.splice(2, 0, phasec24);

            this.forceRerender0();


             this.forceRerender();
             this.forceRerender2();
             this.forceRerender3();


          this.statsCards[1].footer = '<i class="tim-icons icon-sound-wave"></i></i>Current';

          var vol1= {
            title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };
 this.statsCards.splice(2, 0, vol1);

 var vol2= {
  title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };



          this.statsCards.splice(3, 0, vol2);

          var total= {
          title: '0.00',
          subTitle: 'Total Active Power',
          type: 'warning',
          icon: 'tim-icons icon-money-coins',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };




          this.statsCards.splice(4, 0, total);



          this.statsCards.push({
            title: '0.00',
          subTitle: 'Watts',
          type: 'info',
          icon: 'tim-icons icon-chart-bar-32',
          footer: '<i class="tim-icons icon-sound-wave"></i> Phase C',
          route: ''
 })


          break;

          case 'meter3-400':


         var phasec24= {
              label: 'Phase C',
              fill: true,
              borderColor: config.colors.default,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.default,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
             // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }

            var phasec= {
              label: 'Phase C',
              fill: true,
              borderColor: config.colors.default,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.default,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
             // yAxisID: 'A',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }

             this.lineChart1.chartData.datasets.splice(2, 0, phasec);
            this.lineChart2.chartData.datasets.splice(2, 0, phasec24);
            this.lineChart3.chartData.datasets.splice(2, 0, phasec24);
            this.lineChart4.chartData.datasets.splice(2, 0, phasec24);

            this.forceRerender0();


             this.forceRerender();
             this.forceRerender2();
             this.forceRerender3();


          this.statsCards[1].footer = '<i class="tim-icons icon-sound-wave"></i></i>Current';

          var vol1= {
            title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };
 this.statsCards.splice(2, 0, vol1);

 var vol2= {
  title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };



          this.statsCards.splice(3, 0, vol2);

          var total= {
          title: '0.00',
          subTitle: 'Total Active Power',
          type: 'warning',
          icon: 'tim-icons icon-money-coins',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>Current',
          route: ''
 };




          this.statsCards.splice(4, 0, total);



          this.statsCards.push({
            title: '0.00',
          subTitle: 'Watts',
          type: 'info',
          icon: 'tim-icons icon-chart-bar-32',
          footer: 'C <i class="tim-icons icon-sound-wave"></i>',
          route: ''
 })


          
          break;
       
        default:

       // this.lineChart1.chartData.datasets.splice(2, 1);
        this.forceRerender0();


          break;
       }



       



    },
    async checkdevices(meter){


      const { data } = await supabase
  .from('relay')
  .select('id,device_code,desc,num,priority,timestamp,device,type')
  .eq('type','device')
  .eq('device_code',meter)
    
       //     console.log("info devices: ", data);
             var devices = data;
           devices.forEach(device=>{

         //   console.log("device: ", device);

            this.devices.push(device);
  
  

      }); 



    },
    async Temp() {
      var start_date_time = moment().subtract(23, 'hours').startOf('hour').unix();
      var end_date_time = moment().add(1, 'hours').startOf('hour').unix()
      var start_date = moment.unix(start_date_time).format("YYYY-MM-DD");
      var end_date = moment.unix(end_date_time).format("YYYY-MM-DD");
      var start_date_time_30d = moment().subtract(30, 'days').startOf('day').unix();
      var start_date_30d = moment.unix(start_date_time_30d).format("YYYY-MM-DD");
    //  this.lineChart2.chartData.datasets[1].data = await this.Temperature_24h(start_date_time, end_date_time, start_date, end_date);
    //  this.lineChart3.chartData.datasets[1].data = await this.Temperature_30d(start_date_30d, end_date);
      this.forceRerender();
    },
    async Temperature_24h(start_date_time, end_date_time, start_date, end_date) {
      const parameter = "&hourly=temperature_2m";
      const timezone = "&timezone=America%2FNew_York";
      const resp = await this.Req(parameter, start_date, end_date,timezone);
      var time = [];
      var data_temp = [];
      var temperature = [];
      time = resp['hourly']['time'];
      temperature = resp['hourly']['temperature_2m'];
      for (var i = 0; i < time.length; i++) {
        if (time[i] > start_date_time && time[i] <= end_date_time) {
          data_temp.push(temperature[i]);
        }
      }
      return data_temp;
    },

    async Temperature_30d(start_date_30d, end_date){
      const parameter = "&daily=temperature_2m_max,temperature_2m_min";
      const timezone = "&timezone=America%2FNew_York";
      const resp = await this.Req(parameter, start_date_30d, end_date, timezone);
      var temp_max = [];
      var temp_min = [];
      var temp_average = []
      temp_max = resp['daily']['temperature_2m_max'];
      temp_min = resp['daily']['temperature_2m_min'];
      for (var i = 0; i < temp_max.length; i++) {
        temp_average.push(parseFloat((temp_max[i] + temp_min[i]) / 2).toFixed(2));
      }
      return temp_average;
    },
    async Temperature_custom(from, to, start_date_1_week,) {
      const parameter = "&daily=temperature_2m_max,temperature_2m_min";
      const timezone = "&timezone=America%2FNew_York";
      var temp_max = [];
      var temp_min = [];
      var temp_average_custom = [];

      if(moment(to).unix() >= moment(start_date_1_week).unix()){
        const resp = await this.Request_hist(parameter, from, to, timezone);
        const resp_7_days = await this.Req(parameter, start_date_1_week, to, timezone);
        temp_max = ((resp['daily']['temperature_2m_max']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_max']);
        temp_min = ((resp['daily']['temperature_2m_min']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_min']);
      }
      else{
        const resp = await this.Request_hist(parameter, from, to, timezone);
        temp_max = ((resp['daily']['temperature_2m_max']));
        temp_min = ((resp['daily']['temperature_2m_min']));
      }
        for (var a = 0; a < temp_min.length; a++) {
          temp_average_custom.push(parseFloat((temp_max[a] + temp_min[a]) / 2).toFixed(2));
        }
      console.log(temp_average_custom);
      return temp_average_custom;

    },

    async Temperature_Yearly(){
      var start_date_year = (moment.unix(moment().unix()).format("YYYY")) + "-01-01";
      var first_week = moment((moment.unix(moment().unix()).format("YYYY")) + "-01-07").unix();
      var start_date_1_week = moment.unix(moment().subtract(144, 'hours').startOf('hour').unix()).format("YYYY-MM-DD");
      var end_date_year = moment.unix(moment().unix()).format("YYYY-MM-DD");
      const parameter = "&daily=temperature_2m_max,temperature_2m_min";
      const timezone = "&timezone=America%2FNew_York";
      var temp_max = [];
      var temp_min = [];
      var temp_average = [];
      var temp_average_year = [0,0,0,0,0,0,0,0,0,0,0,0];
      var average = 0;
      var time = [];
      var month = 1;
      var count = 0;
      const resp = await this.Request_hist(parameter, start_date_year, end_date_year, timezone);
      const resp_7_days = await this.Req(parameter, start_date_1_week, end_date_year,timezone);
      //console.log(moment(start_date_1_week).unix(), first_week);
      if (moment(start_date_1_week).unix() <= first_week  ){
        temp_max = resp_7_days['daily']['temperature_2m_max'];
        temp_min = resp_7_days['daily']['temperature_2m_min'];
        time = resp['daily']['time'];
        for (var i = 0; i < temp_max.length; i++) {
          temp_average.push(parseFloat((temp_max[i] + temp_min[i]) / 2).toFixed(2));
        }
        temp_average_year[0]=(parseFloat(temp_average / temp_average.length).toFixed(2))
        //console.log( temp_average_year);
        return temp_average;
      }
      else {
      temp_max = ((resp['daily']['temperature_2m_max']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_max']);
      temp_min = ((resp['daily']['temperature_2m_min']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_min']);
      time = resp['daily']['time'];
      for (var a = 0; a < time.length; a++) {
        time[a] = (moment.unix(time[a]).format("M"));
        temp_average.push((temp_max[a] + temp_min[a]) / 2);
      }
      for (var j = 0; j <= (time.length ); j++) {
        if (time[j] == month && j < (time.length)) {
          average = average + temp_average[j]
          count++;
        }
        else if (j == ((time.length))) {
          temp_average_year[month -1]=(parseFloat(average / count).toFixed(2));
        }
        else {
          temp_average_year[month - 1] =(parseFloat(average / count).toFixed(2));
          average = 0;
          count = 0;
          month++;
        }
      }}
      //console.log(temp_average_year);
      return temp_average_year;

    },

    async Req(parameter, start_date, end_date,timezone) {
      const longitude = parseFloat(this.user.lng).toFixed(2);
      const latitude = parseFloat(this.user.lat).toFixed(2);
      var url = 'https://api.open-meteo.com/v1/forecast?latitude='+latitude+'&longitude='+longitude+parameter+'&temperature_unit=fahrenheit&timeformat=unixtime'+timezone+'&start_date='+start_date+'&end_date='+end_date;
      try {
        const response = await axios.get(url);
        const data = response.data
        //console.log(response.data);
        return data;
      } catch (e) {
        console.log(e.response.data.error)
      }
       
    },

    async Request_hist(parameter, start_date, end_date, timezone) {
      const longitude = this.user.lng;
      const latitude = this.user.lat;
      var url = 'https://archive-api.open-meteo.com/v1/archive?latitude='+latitude+'&longitude='+longitude+'&start_date='+start_date+'&end_date='+end_date+parameter+timezone+'&temperature_unit=fahrenheit&timeformat=unixtime';
      try {
      const response = await axios.get(url);
      const data = response.data
      return data;
    } catch (e) {
    console.log(e.response.data.error)
    }
},
    info(info, ip) {

      //alert(info);


      const mqtt = firebase.functions().httpsCallable('mqtt');

      mqtt({ ip: ip, ch: info })
        .then(response => {
          console.log(response);
        })

    },

    teste() {
      // Simple POST request with a JSON body using fetch
      // const requestOptions = {
      //   method: "GET",
      //   headers: { "Content-Type": "application/json" },
      // headers: { "Access-Control-Allow-Headers": "Content-Type" },

      /// headers: { 'Access-Control-Allow-Headers': 'Authorization' },


      //     headers: {"Access-Control-Allow-Origin": "*"},
      // headers: {"Access-Control-Allow-Origin": "http://localhost:8080"},
      // headers: {"Access-Control-Allow-Credentials": "true"},
      // headers: {"Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"},
      // headers: {"Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"},


      // body: JSON.stringify({ title: "Vue POST Request Example" })https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a
      // };//https://api.npms.io/v2/search?q=vue
      // fetch("https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",requestOptions)
      //  .then(response => response.json())
      //   .then(data => (

      //     console.log(data)

      //     ));

      //   request(
      //     "https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",
      //     {
      //       method: "GET",
      //       mode: "cors",
      //       cache: "default",
      //     }
      //   ).then(response => response.json())
      // .then(data => (

      //   console.log(data)

      //   ));




    },
    async createPost() {
      //http://localhost:8080  api.enphaseenergy.com /api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a



      // axios
      // .get('https://us-central1-martinson-machine.cloudfunctions.net/testmail')
      // .then(res => {
      //   console.log(`statusCode: ${res.statusCode}`)
      //   console.log(res.data)
      // })
      // .catch(error => {
      //   console.error(error)
      //  })


      //   const headers = new Headers();
      //       headers.append(
      //         "Access-Control-Allow-Origin",
      //         "https://app.martinsonmachine.com"
      //       );
      //        headers.append(
      //          "Access-Control-Allow-Headers",
      // "Origin, Accept,  X-Requested-With,   Content-Type,   Access-Control-Request-Method,    Access-Control-Request-Headers,  Authorization" );
      //       headers.append(
      //         "Access-Control-Allow-Credentials",
      //         "true"
      //       );
      const request = new Request(
        "/enphaseenergy/?dest=111111",
        // "/firebase/?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",
        {
          method: "GET",
          mode: "cors",
          // headers,
          cache: "default",
          // body: JSON.stringify(this.post)
        }
      );
      const res = await fetch(request);
      // const data = await res.json();
      console.log(res)



    },
    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return time;
    },
   
    disabledDate1(date) {
     
       if (this.from) {
        var result =moment(this.from);
       // console.log("result:",result)
        let finaldate = moment(result).add(30,'days');
       // console.log("finaldate:",finaldate)

        if(date>finaldate)
        {
          return true
        }else{
          return this.from > date
        }

         
       }
       return false;
     },
    async load() {

      // let timefrom = new Date(this.from);

      // let timeto = new Date(this.to);
      this.customload = true;
   
      //this.lineChart4.chartData.datasets[1].data = await this.Temperature_custom(from, to, start_date_1_week,);
      // if (this.user.solar > 0) {
      //   this.lineChart4.chartData.datasets[1].data = []
      // }
      let a = moment(this.from);
      let b = moment(this.to);

      //console.log(b.diff(a, 'hours'))

      let timeframe = b.diff(a, 'hours');

      this.custom = [];
      this.custom8 = [];

      if (timeframe > 24) {

        let now = new Date();


        let test = moment(now);

        let timeframe = test.diff(a, 'days');
        let diff = b.diff(a, 'days');

 
        var { data, error } = await supabase.rpc('customdays_energy', { meter:this.meter.code,datain:a.format(),datainout:b.format(), zone:""  })
        console.log("#####");
if (error) console.error(error)
//else console.log("customdays_energy",data);

        this.lineChart4.chartData.datasets[0].data = [];
        this.lineChart4.chartData.datasets[1].data = [];
        this.lineChart4.chartData.datasets[2].data = [];
          if(this.meter.tipo!=='meter')
            {
              this.lineChart4.chartData.datasets[3].data = [];
            }
        for (let dia1 = timeframe; dia1 > timeframe - diff; dia1--) {
        //  console.log(moment().subtract(dia1, 'days').format("D-MMM"));

          this.custom.push(moment().subtract(dia1, 'days').format("D-MMM"))
          this.custom8.push(moment().subtract(dia1, 'days').format("D"))
          const result = data.find(({ time }) => time === parseFloat(moment().subtract(dia1, 'days').format("D")));
          let one =result?(result.one_consumption).toFixed(2):0;
          let two =result?(result.two_consumption).toFixed(2):0;

          this.lineChart4.chartData.datasets[0].data.push(parseFloat(one));
          this.lineChart4.chartData.datasets[1].data.push(parseFloat(two));
          if(this.meter.tipo==='meter'){
          let total =result?(result.total_consumption).toFixed(2):0;
          this.lineChart4.chartData.datasets[2].data.push(parseFloat(total));
          }else{
            let three =result?(result.three_consumption).toFixed(2):0;
            let total =result?(result.total_consumption).toFixed(2):0;
            this.lineChart4.chartData.datasets[2].data.push(parseFloat(three));
            this.lineChart4.chartData.datasets[3].data.push(parseFloat(total));
          }
       
          //await this.month(dia1, this.meter);//"shellyem-D3B144"
      
       

          // if (this.user.solar > 0) {

          //   let solar = await this.monthSolar(dia1, this.meter)

          //   this.lineChart4.chartData.datasets[1].data.push(solar)
          // }


             if(dia1%5===0){
          this.forceRerender2()

             }



        }
        this.lineChart4.chartData.labels = this.custom;
         if(this.meter.tipo==='meter'){
        this.totalcustom = this.lineChart4.chartData.datasets[2].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
         }else{
          this.totalcustom = this.lineChart4.chartData.datasets[3].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
         }
        // if (this.user.solar > 0) {
        //   this.totalcustomsolar = this.lineChart4.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
        // }
        this.customload = false;
        this.forceRerender3();
        //console.log("source chats custom inside:",this.lineChart4.chartData.datasets)
      }
      else {


///finish supabase

//console.log("#####hours a:",a.format());

var { data, error } = await supabase.rpc('customhours_energy', { meter:this.meter.code,datain:a.format(),datainout:b.format(), zone:""  })
    //    console.log("#####hours");
if (error) console.error(error)
//else console.log("customhours_energy",data);

        this.lineChart4.chartData.datasets[0].data = [];
        this.lineChart4.chartData.datasets[1].data = [];
        this.lineChart4.chartData.datasets[2].data = [];
        this.custom=[]
        this.custom8=[]
         if(this.meter.tipo!=='meter')
            {
              this.lineChart4.chartData.datasets[3].data = [];
            }

        for (let hour = timeframe - 1; hour > -1; hour--) {

          this.custom.push(moment(this.to).subtract(hour+1, 'hours').format("hA"))
          this.custom8.push(moment(this.to).subtract(hour+1, 'hours').hours())

          //  console.log("timeframe-hour",timeframe-hour-1);  
          //  console.log("hour",hour); 
          //   console.log("hour resut",moment(a).add(timeframe-hour-1, 'hours').format("H")); 

         // .find(({ time }) => time === parseFloat(moment().subtract(hour, 'hours').format("h")));

          const result = data.find(({ time }) => time === Number(moment(a).add(timeframe-hour-1, 'hours').format("H")));
          // data.find(({ time }) => time === parseFloat(moment().subtract(hour, 'hours').format("h")));
        //  console.log(result);
          let one =result?(result.one_consumption).toFixed(2):0;
          let two =result?(result.two_consumption).toFixed(2):0;
          //await this.month(dia1, this.meter);//"shellyem-D3B144"
          this.lineChart4.chartData.datasets[0].data.push(parseFloat(one));
          this.lineChart4.chartData.datasets[1].data.push(parseFloat(two));

          if(this.meter.tipo==='meter'){
          let total =result?(result.total_consumption).toFixed(2):0;
          this.lineChart4.chartData.datasets[2].data.push(parseFloat(total));
          }else{
            let three =result?(result.three_consumption).toFixed(2):0;
            let total =result?(result.total_consumption).toFixed(2):0;
            this.lineChart4.chartData.datasets[2].data.push(parseFloat(three));
            this.lineChart4.chartData.datasets[3].data.push(parseFloat(total));
          }

          //  const time = timeframe-1-hour;

          // let info = await this.dayhere(this.to, hour, this.meter)

          // this.lineChart4.chartData.datasets[0].data.push(info)

          // if (this.user.solar > 0) {

          //   let solar = await this.dayheresolar(this.to, hour, this.meter)

          //   this.lineChart4.chartData.datasets[1].data.push(solar)
          // }
        }

        this.lineChart4.chartData.labels = this.custom;
        if(this.meter.tipo==='meter'){
        this.totalcustom = this.lineChart4.chartData.datasets[2].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
        }else{
          this.totalcustom = this.lineChart4.chartData.datasets[3].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
        }
        // if (this.user.solar > 0) {
        //   this.totalcustomsolar = this.lineChart4.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
        // }
        this.customload = false;
        this.forceRerender3();

      }

    },
    forceRerender0() {
      console.log("charts render 0");
      this.componentKey0 += 1;
    },
    forceRerender() {
      console.log("charts render 1");
      this.componentKey += 1;
    },
    forceRerender2() {
      console.log("charts render 2 ");
      this.componentKey2 += 1;
    },
    forceRerender3() {
      console.log("charts render 3 ");
      this.componentKey3 += 1;
    },
    async change(index) {
      //console.log(index);
      this.selected = index;

    //  this.forceRerender();
      if (index === 1) {

        this.componentKey2=0
        this.lineChart3.chartData.datasets[0].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ,0,0,0,0,0,0,0];
        this.lineChart3.chartData.datasets[1].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0,0,0,0,0,0,0];
        this.lineChart3.chartData.datasets[2].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0,0,0,0,0,0,0];
        if(this.meter.tipo!=='meter'){
          this.lineChart3.chartData.datasets[3].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ,0,0,0,0,0,0,0];
        }
        this.forceRerender2();

         const { data, error } = await supabase.rpc('trintad_energy', { meter:this.meter.code,year:moment().local().format("YYYY"),month:moment().local().format("M"), zone:""  })
        console.log("#####");
if (error) console.error(error)
//else console.log(data);


        this.days = []
        for (let dia1 = 30; dia1 > -1; dia1--) {

        // console.log(moment().subtract(dia1, 'days').format("D"));

          const result = data.find(({ time }) => time === parseFloat(moment().subtract(dia1, 'days').format("D")));

         // console.log(result);

          this.days.push(moment().utcOffset(-240).subtract(dia1, 'days').format("D-MMM"))
          this.days30.push(moment().utcOffset(-240).subtract(dia1, 'days').format("D"))


        //  this.lineChart3.chartData.datasets[0].data[30 - dia1] = result?(result.total_consumption?result.total_consumption:0).toFixed(2):0;

          this.lineChart3.chartData.datasets[0].data[30 - dia1] = result?(result.one_consumption?result.one_consumption:0).toFixed(2):0;
      this.lineChart3.chartData.datasets[1].data[30 - dia1] = result?(result.two_consumption?result.two_consumption:0).toFixed(2):0;
      if(this.meter.tipo==='meter'){
      this.lineChart3.chartData.datasets[2].data[30 - dia1] = result?(result.total_consumption?result.total_consumption:0).toFixed(2):0;
      }else{
       this.lineChart3.chartData.datasets[2].data[30 - dia1] = result?(result.three_consumption?result.three_consumption:0).toFixed(2):0;
        this.lineChart3.chartData.datasets[3].data[30 - dia1] = result?(result.total_consumption?result.total_consumption:0).toFixed(2):0;


      }
          

          // if (this.user.solar > 0) {

          //   this.lineChart3.chartData.datasets[1].data[30 - dia1] = await this.monthSolar(dia1, this.meter)
          // }
          //console.log(dia1)
          // console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
          this.lasttime = moment().valueOf();

          if (dia1 % 5 === 0) {
            this.forceRerender2()

          }

        }
        this.lineChart3.chartData.labels = this.days;

        if(this.meter.tipo==='meter'){

        this.total30 = this.lineChart3.chartData.datasets[2].data.reduce((acc, item) => acc + parseFloat(item), 0)
        }else{
          this.total30 = this.lineChart3.chartData.datasets[3].data.reduce((acc, item) => acc + parseFloat(item), 0)

        }

        // if (this.user.solar > 0) {
        //   this.total30solar = this.lineChart3.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
     

      }else if(index===0){
        this.componentKey=0;
        this.lineChart2.chartData.datasets[0].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.lineChart2.chartData.datasets[1].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.lineChart2.chartData.datasets[2].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if(this.meter.tipo!=='meter'){
          this.lineChart2.chartData.datasets[3].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        this.forceRerender()
        this.chart24()
       

        // this.lineChart2 = this.chart24;
        // console.log("source chats 24hr:",this.chart24.chartData.datasets[2].data.length)
        // console.log("source chats 24hr:",this.chart24.chartData.datasets[2].data)

      }else{

        this.lineChart4.chartData.datasets[0].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.lineChart4.chartData.datasets[1].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.lineChart4.chartData.datasets[2].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if(this.meter.tipo!=='meter'){
          this.lineChart4.chartData.datasets[3].data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        this.forceRerender3()




      }




    },
  }
};
</script>
<style>

</style>